
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ROUTES } from "@/data";
import { Action, Getter, Mutation, State } from "vuex-class";
import { IAgencyDB, StoreAction, StoreMutation } from "@/types";
import { showModal } from "@/helpers/callables";
import LockedAction from "@/components/modal/LockedAction.vue";

@Component({
    name: "LeftBar",
    components: { LockedAction },
})
export default class LeftBar extends Vue {
    selectedLink: any = null;
    selectedView: any = null;
    newCandidateCount: number = 0;
    candidateTimeout: any;
    showLockedAction = false;
    changedStateLeftbar: boolean = false;
    menuClosed: boolean = true;
    $refs!: any;
    opened: any = {
        profile: false,
    };

    @State("currentAgency") currentAgency!: IAgencyDB;
    @State("configuration") configuration!: any;
    @Getter("isInteractionLimited") isInteractionLimited!: boolean;
    @Action("actions/getNewCandidateCount") getNewCandidateCount!: StoreAction;
    @Getter("isCompany") isCompany!: any;
    @Getter("isAgency") isAgency!: any;
    @Getter("isSupervisor") isSupervisor!: any;
    @Getter("isArchives") isArchives!: any;
    @Getter("isInteraction") isInteraction!: any;
    @State("supervisorAgencyId") supervisorAgencyId!: any;
    @Getter("userFullName") fullName!: string;
    @Getter("userPicture") userPicture!: string;
    @Getter("userPosition") position!: string;
    @Getter("hasATS") hasATS!: boolean;
    @Getter("hasJobOffers") hasJobOffers!: boolean;
    @Getter("allUserViews") allUserViews!: any;
    @State("lastSelectedView") lastSelectedView!: any;
    @State("selectedView") storeSelectedView!: any;
    @Mutation("setSelectedView") setSelectedView!: StoreMutation;
    @Mutation("setLastSelectedView") setLastSelectedView!: StoreMutation;
    @Mutation("toggleMenuClose") toggleMenuClose!: StoreMutation;

    @Watch("storeSelectedView")
    onStoreSelectedView(n: any, o: any) {
        if (n !== o) {
            this.selectedView = n;
        }
    }

    get isATS() {
        return this.$route.name?.indexOf("ats") !== -1;
    }

    get links() {
        let toRet: any = [];
        if (this.isAgency || this.isSupervisor) {
            toRet = [
                {
                    icon: "layout",
                    routeName: ROUTES.ATS.SUPERVISOR_DASHBOARD,
                    label: this.$t("page.atsLayout.leftBar.homeSupervisor"),
                    views: [],
                    visible: this.isSupervisor,
                    splittable: true,
                },
                {
                    icon: "bar3",
                    routeName: ROUTES.ATS.SUPERVISOR_STATISTICS,
                    label: this.$t("page.atsLayout.leftBar.stats"),
                    views: [],
                    visible: this.isSupervisor,
                    splittable: true,
                },
                {
                    icon: "layout",
                    routeName: ROUTES.APP.HOME,
                    classes: "home",
                    label: this.$t("page.atsLayout.leftBar.home"),
                    views: [],
                    visible: true,
                },
                {
                    icon: "edit",
                    routeName: ROUTES.APP.FOLLOWING,
                    label: this.$t("page.atsLayout.leftBar.commands"),
                    views: this.getViews(ROUTES.APP.FOLLOWING),
                    visible: this.isAgency && this.currentAgency.subscriptionType !== "starter",
                    routeDefaultParams: { query: { mode: "suivi-global" } },
                },
                {
                    icon: "calendar",
                    routeName: ROUTES.APP.PLANNING,
                    label: this.$t("layout.left.planning"),
                    views: this.getViews(ROUTES.APP.PLANNING),
                    visible: this.isAgency && this.currentAgency.subscriptionType !== "starter",
                    routeDefaultParams: { query: { mode: "week" } },
                },
                {
                    icon: "interim2",
                    routeName: ROUTES.APP.INTERIMS,
                    label: this.$t("page.atsLayout.leftBar.interims"),
                    views: this.getViews(ROUTES.APP.INTERIMS),
                    visible: this.isAgency && this.currentAgency.subscriptionType !== "starter",
                    routeDefaultParams: {
                        query: { mode: "workers" },
                    },
                },
                {
                    icon: "clients2",
                    routeName: ROUTES.APP.CLIENTS,
                    label: this.$t("page.atsLayout.leftBar.clients"),
                    views: this.getViews(ROUTES.APP.CLIENTS),
                    visible: this.isAgency && this.currentAgency.subscriptionType !== "starter",
                },
                {
                    icon: "listcheck",
                    routeName: ROUTES.ATS._ROOT,
                    label: this.$t("page.atsLayout.leftBar.ats"),
                    views: [
                        {
                            icon: "search",
                            route: ROUTES.ATS.FOLLOWING._ROOT,
                            label: ROUTES.ATS.FOLLOWING._ROOT,
                            views: [
                                {
                                    name: ROUTES.ATS.FOLLOWING.GLOBAL,
                                    label: this.$t("layout.left.globalFollowing"),
                                    route: {
                                        name: ROUTES.ATS.FOLLOWING.GLOBAL,
                                    },
                                },
                                {
                                    name: ROUTES.ATS.FOLLOWING.PLANNING,
                                    label: this.$t("layout.left.planning"),
                                    route: {
                                        name: ROUTES.ATS.FOLLOWING.PLANNING,
                                    },
                                },
                                {
                                    name: ROUTES.ATS.FOLLOWING.IDENTIFICATION,
                                    label: "Doublons",
                                    route: {
                                        name: ROUTES.ATS.FOLLOWING.IDENTIFICATION,
                                        displayCount: true,
                                    },
                                },
                            ],
                            visible: true,
                            opened: false,
                        },
                        {
                            icon: "cvtheque",
                            routeName: ROUTES.ATS.CV._ROOT,
                            label: ROUTES.ATS.CV._ROOT,
                            views: [
                                {
                                    name: ROUTES.ATS.CV.LIST,
                                    label: this.$t("layout.left.cvSearch"),
                                    route: {
                                        name: ROUTES.ATS.CV.LIST,
                                    },
                                },
                            ],
                            visible: true,
                            opened: false,
                        },
                        {
                            icon: "megaphone",
                            routeName: ROUTES.ATS.JOBOFFER._ROOT,
                            label: this.$t("layout.left.jobOffer"),
                            views: [],
                            visible: this.hasJobOffers,
                            opened: false,
                        },
                    ],
                    visible: this.isAgency && this.currentAgency.subscriptionType !== "starter" && this.hasATS,
                },
                {
                    icon: "bar3",
                    routeName: ROUTES.APP.STATISTICS,
                    label: this.$t("page.atsLayout.leftBar.stats"),
                    views: [],
                    visible: this.isAgency && this.currentAgency.subscriptionType !== "starter",
                },
                {
                    icon: "agencyProfile2",
                    routeName: ROUTES.APP.AGENCYPROFILE,
                    label: this.$t("page.atsLayout.leftBar.agencyProfile"),
                    views: [],
                    visible: this.isAgency && this.currentAgency.subscriptionType !== "starter",
                },
            ];
        } else if (this.isCompany) {
            toRet = [
                {
                    icon: "following",
                    routeName: ROUTES.APP.FOLLOWING,
                    label: this.$t("layout.left.following"),
                    views: this.getViews(ROUTES.APP.FOLLOWING),
                    visible: true,
                },
                {
                    icon: "calendar",
                    routeName: ROUTES.APP.PLANNING,
                    label: this.$t("layout.left.planning"),
                    views: this.getViews(ROUTES.APP.PLANNING),
                    visible: true,
                },
                {
                    icon: "interim",
                    routeName: ROUTES.APP.INTERIMS,
                    label: this.$t("layout.left.interims"),
                    views: [],
                    visible: true,
                },
                {
                    icon: "agency",
                    routeName: ROUTES.APP.AGENCIES,
                    label: this.$t("layout.left.agencies"),
                    views: [],
                    visible: true,
                },
                {
                    icon: "agencyProfile",
                    routeName: ROUTES.APP.COMPANYPROFILE,
                    label: this.$t("layout.left.company"),
                    views: [],
                    visible: true,
                },
            ];
        }

        return toRet.filter((link: any) => link.visible);
    }

    get profileRoute() {
        return {
            name: ROUTES.APP.PROFILE,
        };
    }

    get splittableLinks() {
        return this.links.filter((link: any) => link.splittable);
    }

    get otherLinks() {
        return this.links.filter((link: any) => !link.splittable);
    }

    get currentRoute() {
        return this.$route.name;
    }

    isActiveSublink(sublink: any) {
        return sublink.views.find((view: any) => view.name === this.$route.name);
    }

    pushViewsBottom(views: any) {
        views.sort((a: any, b: any) => {
            return a.editable - b.editable;
        }); // on push au fond ceux qui sont éditable (créé par l'utilisateur)
        return views;
    }

    getViews(context: any) {
        const appRoute = this.$router.options.routes!.find((route) => route.name === ROUTES.APP._ROOT);
        if (!appRoute || !appRoute.children?.length) return [];

        const contextRoute = appRoute.children.find((route) => route.name === context);
        let views = contextRoute!.children || [];

        if (typeof context !== "string") {
            if (context.only && context.name) {
                views = this.allUserViews(context.name).filter((i: any) => context.only.includes(i.nameSlug));
            }
        }

        return views.map((i: any) => this.getView(i, context));
    }

    getView(route: any, context: any = {}) {
        return {
            ...route.meta,
            views: route.children || [],
            opened: false,
            label: route.name,
            route: { name: context.name },
            visible: true,
            isView: true,
        };
    }

    isActive(sublink: any) {
        if (this.selectedView && sublink.isView) {
            return this.selectedView.nameSlug === sublink.nameSlug;
        }
        if (sublink.views?.length) {
            return sublink.views.find((v: any) => this.isActive(v));
        }
        return this.$route.name === (sublink.routeName || sublink.route?.name);
    }

    openNewCandidate() {
        // todo : open modal new-candidate (n'existe pas encore)
    }

    displaylock() {
        showModal.call(this, "LockedAction");
    }

    goToOrderCreation() {
        this.$router.push({ name: ROUTES.APP.MISSIONCREATION, query: { new: "1" } });
    }

    clickAction() {
        if (this.isATS) {
            this.goToNewCampain();
        } else {
            this.goToOrderCreation();
        }
    }

    goToHome() {
        this.selectedView = null;
        if (this.isSupervisor) {
            if (!this.supervisorAgencyId) {
                if (this.$route.name !== ROUTES.ATS.SUPERVISOR_DASHBOARD) {
                    this.$router.push({ name: ROUTES.ATS.SUPERVISOR_DASHBOARD });
                }
            } else if (this.$route.name !== ROUTES.APP.HOME) {
                this.$router.push({ name: ROUTES.APP.HOME });
            }
        } else if (this.$route.name !== ROUTES.APP.HOME) {
            this.$router.push({ name: ROUTES.APP.HOME });
        }
    }

    goToProfile(goBS: boolean) {
        this.opened.profile = false;
        if (this.$route.name !== ROUTES.APP.PROFILE) {
            const route: any = this.profileRoute;
            if (goBS) {
                route.query = { bs: "1" };
            }
            this.$router.push(route);
        }
    }

    async goToNewCampain() {
        if (this.$route.name !== ROUTES.ATS.JOBOFFER.ADD) {
            await this.$router.push({ name: ROUTES.ATS.JOBOFFER.ADD });
        }
    }

    goTo(route: any) {
        if (route.name === ROUTES.ATS.CV.NEWCANDIDAT && this.isSupervisor) {
            this.$toast.open({
                message: "Action impossible en tant que superviseur",
                type: "error",
                duration: 2000,
            });
            return;
        }

        if (this.$route.name !== route.name) {
            this.$router.push(route);
        }
    }

    selectLink(link: any, reset?: boolean) {
        this.selectedLink = link;
    }

    toggleSubLink(sublink: any) {
        if (sublink?.isView) return this.selectView(sublink);

        this.selectedLink.views?.forEach((sl: any) => (sl.opened = false));
        if (sublink.views?.length) sublink.opened = !sublink.opened;
    }

    checkAncestors(currentRoute: any, name: string) {
        if (currentRoute.name === name || (currentRoute.matched?.length && currentRoute.matched.find((m: any) => m.name === name))) {
            return true;
        }
        return null;
    }

    mainLinkClicked(link: any) {
        this.selectedView = null;
        this.selectLink(link, true);
    }

    selectView(view: any) {
        if (view.route && this.$route.name !== view.route?.name) {
            this.$router.push({ name: view.route.name || "", query: { mode: view.nameSlug } });
        }
        if (this.$route.query?.mode !== view.nameSlug) {
            this.$router.push({ name: this.$route.name || "", query: { mode: view.nameSlug } });
        }

        this.setLastSelectedView({ route: this.$route.name, view });
        this.selectedView = view;
    }

    selectFirstView() {
        if (
            this.configuration.views &&
            this.configuration.views[<string>this.$route.name] &&
            this.configuration.views[<string>this.$route.name].preconfiguredViews &&
            this.configuration.views[<string>this.$route.name].preconfiguredViews[0]
        ) {
            this.selectView(this.configuration.views[<string>this.$route.name].preconfiguredViews[0]);
        }
    }

    manageMenu() {
        this.toggleMenuClose(true);
    }

    mouseEnterLeftbar() {
        this.menuClosed = false;
    }
    mouseLeaveLeftbar() {
        this.menuClosed = true;
    }

    async refreshCandidateCount() {
        if (this.isSupervisor && this.supervisorAgencyId) {
            this.newCandidateCount = await this.getNewCandidateCount();
            this.candidateTimeout = setTimeout(() => {
                if (this.isAgency) {
                    this.refreshCandidateCount();
                }
            }, 60000);
        }
    }

    beforeMount() {
        try {
            this.refreshCandidateCount();
            this.selectedLink = this.links.find((mainlink: any) => {
                return mainlink.views.find((sublink: any) => {
                    return sublink.label === this.$route.name || sublink.views.find((sublinkView: any) => sublinkView.name === this.$route.name);
                });
            });
        } catch (e) {
            console.error(e);
        }
    }

    beforeDestroy() {
        clearTimeout(this.candidateTimeout);
    }
}
