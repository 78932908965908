
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import LayoutTooltip from "@/components/layoutTooltips/LayoutTooltip.vue";
import { ROUTES } from "@/data";
import { Action, Getter, Mutation, State } from "vuex-class";
import { IAgencyDB, IUserDB, StoreAction, StoreMutation } from "@/types";
import { capitalize } from "@/helpers/commons";
import Button from "@/dsComponents/buttons/Button.vue";

@Component({
    name: "TopBar",
    components: { LayoutTooltip, Button },
})
export default class TopBar extends Vue {
    @Prop() title!: string;
    @Prop() loading!: boolean;

    @State("currentAgency") currentAgency!: IAgencyDB;
    @State("collaborators") collaborators!: IUserDB[];
    @State("totalUnreadNotifications") totalUnreadNotifications!: number;
    @Getter("userFullName") fullName!: string;
    @Getter("userPosition") position!: string;
    @Getter("userPicture") userPicture!: string;
    @Getter("isAgency") isAgency!: any;
    @Getter("isCompany") isCompany!: any;
    @Getter("isInteraction") isInteraction!: Boolean;

    @Action("actions/getUserNotifications") getUserNotifications!: StoreAction;

    @Getter("supervisorAgencies") supervisorAgencies!: IAgencyDB[];
    @State("supervisorAgencyId") supervisorAgencyId!: any;
    @Getter("isSupervisor") isSupervisor!: any;
    @Mutation("setSupervisorAgency") setSupervisorAgency!: StoreMutation;

    opened: any = {
        agencies: false,
        profile: false,
        notifications: false,
        collaborators: false,
    };
    notificationLoading = false;
    apiUrl: string = <string>process.env.VUE_APP_API_URL?.replace("/1.0", "") + "/";
    capitalize = capitalize;

    get profileRoute() {
        return {
            name: ROUTES.APP.PROFILE,
        };
    }

    get topLinks() {
        if (this.loading) {
            return [];
        }

        return [
            {
                id: "notifications",
                icon: "bell3",
                classes: this.totalUnreadNotifications > 0 ? "notification-number" : "",
                label: this.$t("layout.top.notifications"),
                numeric: this.totalUnreadNotifications > 0,
                numericValue: this.totalUnreadNotifications,
                opened: this.opened.notifications,
                visible: this.isCompany || (this.isAgency && this.currentAgency.subscriptionType !== "starter" && !this.isSupervisor),
            },
            {
                id: "history",
                icon: "clock3",
                label: this.$t("layout.top.history"),
                numeric: false,
                opened: false,
                visible: this.isCompany || (this.isAgency && this.currentAgency.subscriptionType !== "starter"),
            },
            {
                id: "collaborators",
                icon: "interim2",
                classes: "collaborator-number",
                label: this.$t("layout.top.mates"),
                numeric: false,
                opened: this.opened.collaborators,
                visible: this.isCompany || (this.isAgency && this.currentAgency.subscriptionType !== "starter"),
            },
        ].filter((link: any) => link.visible);
    }

    @Watch("supervisorAgencyId")
    onSupervisorAgencyId() {
        this.$emit("reload");
    }

    @Watch("loading")
    onLoadingComplete() {
        this.supervisorAgencies.sort(this.compareName);
        this.initchangelogPop();
    }

    get collaboratorNb() {
        return this.collaborators?.length || 0;
    }

    get supervisorNotEvolia() {
        return this.supervisorAgencies.some((agency) => agency.name === "interaction");
    }

    supervisorAgencyPicture(agency?: IAgencyDB) {
        const img = !agency ? this.currentAgency?.logo : agency?.logo;

        return img ? this.apiUrl + img : "";
    }

    selectSupervisorAgency(agency: IAgencyDB) {
        this.setSupervisorAgency(agency);
        this.opened.agencies = false;
        this.$forceUpdate();
    }

    toggle(n: any, v: boolean) {
        if (n.id === "notifications") {
            if (v) {
                this.fetchNotificationsOnly();
            }
        }
        this.opened[n.id] = v;
        this.$set(n, "opened", v);
        this.$forceUpdate();
    }

    goToProfile(goBS: boolean) {
        this.opened.profile = false;
        if (this.$route.name !== ROUTES.APP.PROFILE) {
            const route: any = this.profileRoute;
            if (goBS) {
                route.query = { bs: "1" };
            }
            this.$router.push(route);
        }
    }

    async fetchNotificationsOnly() {
        if (!this.notificationLoading) {
            this.notificationLoading = true;
            try {
                await this.getUserNotifications();
            } catch (e) {
                console.log(e);
            }
            this.notificationLoading = false;
        }
    }

    compareName(a: any, b: any) {
        const name1 = a.address.city.toUpperCase();
        const name2 = b.address.city.toUpperCase();

        let comparison = 0;

        if (name1 > name2) {
            comparison = 1;
        } else if (name1 < name2) {
            comparison = -1;
        }
        return comparison;
    }

    initchangelogPop() {
        const variables = document.createElement("script");

        variables.innerHTML = `var CLF_config = {
            app_id: "${process.env.VUE_APP_CHANGELOGFY_APP_ID}",
            selector: ".changelogfy-widget",

        };`;

        const script = document.createElement("script");

        script.src = process.env.VUE_APP_CHANGELOGFY_API_URL || "";
        script.type = "text/javascript";

        document.head.appendChild(variables);
        document.head.appendChild(script);
    }

    openChangelog() {
        // @ts-ignore
        window.changelogfy.open();

        const changelogfyFrame = document.getElementById("changelogfy-iframe-widget");

        if (changelogfyFrame) {
            // @ts-ignore
            const iframeDocument = changelogfyFrame.contentDocument;
            const head = iframeDocument.head || iframeDocument.getElementsByTagName("head")[0];
            const verifyStylePresence = head.querySelector("style");

            const styleElement = iframeDocument.createElement("style");

            if (!verifyStylePresence) {
                styleElement.textContent = `.tracking-wide { display: none; }
                .text-color-title { font-size: 1.7rem; line-height: 150%; margin: 0;}
                .py-2 { display : none; }`;

                iframeDocument.head.appendChild(styleElement);
            }
        }
    }

    externalLink(link: string | undefined) {
        window.open(link, "_blank");
    }
}
