
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import {ROUTES} from "@/data";
import {Getter, State} from "vuex-class";
import moment from "moment-timezone";

@Component({
    name: "History"
})
export default class History extends Vue {
    @State('links') links!:any;

    moment = moment;

    titles :any = {
        company: "Fiche Entreprise",
        user: "Fiche Intérimaire",
        agency: "Fiche Agence",
        bag: "Fiche Mission",
        target: "Création de Commande"
    }

    @Watch('$route.fullPath')
    onFullPath() {
        this.$emit('close');
    }

    isSame(route:any) {
        return this.$route.name === route.name;
    }
}
