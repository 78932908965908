
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Action, Getter, Mutation, State} from "vuex-class";
import {INotificationDB, StoreAction, StoreMutation} from "@/types";
import {NotificationStatus, ROUTES} from "@/data";
import {isSameRoute} from "@/helpers/commons";
import moment from "moment-timezone";

@Component({
    name: "Notifications"
})
export default class Notifications extends Vue {
    @State('notifications') notifications!: INotificationDB[];
    @Mutation('setNotification') setNotification!: StoreMutation;
    @Getter('isAgency') isAgency!: boolean;
    @Action('actions/readNotifications') readNotifications!:StoreAction;
    @Prop() loading!: boolean;

    display:any = {};
    innerLoading:boolean[] = [];

    get innerNotifications() {
        return this.notifications
            .filter((n:INotificationDB) => n.status === NotificationStatus.unread)
            .sort((a: INotificationDB, b: INotificationDB) => {
                return (b.updatedAt || 0) - (a.updatedAt || 0);
            });
    }

    async goToNotification(notification: INotificationDB, index:number) {
        this.$emit('close');
        this.innerLoading[index] = true;
        try {
            await this.setNotification(await this.readNotifications(notification._id));
            this.$emit("remove", notification._id);
            if (notification.link && !isSameRoute(this.$route, notification.link)) {
                await this.$router.push(notification.link);
            }
        } catch (e) {
            console.log(e);
        }
        this.innerLoading[index] = false;
    }

    goToNotifications()  {
        this.$emit('close');
        if (this.$route.name !== ROUTES.APP.NOTIFICATIONS) {
            this.$router.push({name: ROUTES.APP.NOTIFICATIONS});
        }
    }

    open(index:number) {
        this.$set(this.display, index, true);
    }

    close(index:number) {
        this.$set(this.display, index, false);
    }
}
