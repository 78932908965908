
import {Component, Vue, Prop} from 'vue-property-decorator';
import RoundRadios from "@/components/RoundRadios.vue";
import {Action, Mutation, State} from "vuex-class";
import {IBusinessSectorDB, IUserDB, StoreAction, StoreMutation} from "@/types";
import {ROUTES} from "@/data";

@Component({
    name: "Domains",
    components: {RoundRadios}
})
export default class Domains extends Vue {
    @State('configuration') configuration!: any;
    @Mutation('setSelectedDomains') setSelectedDomains!: StoreMutation;

    get displayed(): IBusinessSectorDB[] {
        return [
            {
                name: "Tous les domaines",
                _id: "all"
            },
            {
                name: "Mes domaines",
                _id: "mine"
            },
            ...this.configuration.businessSectors
        ];
    }

    goToProfile() {
        this.$emit('close');
        if (this.$route.name !== ROUTES.APP.PROFILE) {
            this.$router.push({name: ROUTES.APP.PROFILE, query: {bs: "1"}});
        }
    }

    onSelect(selected: string[]) {
        this.setSelectedDomains(selected);
    }

    domainLabel(domain: IBusinessSectorDB) {
        return domain.name;
    }
}
