
import {Component, Vue, Prop, Watch} from 'vue-property-decorator';

@Component({
    name: "VModel"
})
export default class VModel extends Vue {
    @Prop() value!: any;

    innerValue: any = "";

    @Watch('innerValue', {deep: true})
    onInnerValue() {
        this.$emit('input', this.innerValue);
    }

    @Watch('value', {deep: true})
    onValue() {
        this.innerValue = this.value;
    }

    beforeMount() {
        this.innerValue = this.value;
    }
}
