
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";

    @Component({
        name: "RemoveView"
    })
    export default class RemoveView extends mixins(ModalMixin) {
        name = "RemoveView";

        @Prop() view!: any;
    }
