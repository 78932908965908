
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import Collaborators from "@/components/layoutTooltips/Collaborators.vue";
    import Notifications from "@/components/layoutTooltips/Notifications.vue";
    import Tasks from "@/components/layoutTooltips/Tasks.vue";
    import History from "@/components/layoutTooltips/History.vue";
    import Domains from "@/components/layoutTooltips/Domains.vue";
    import {IUserDB} from "@/types";

    @Component({
        name: "LayoutTooltip",
        components: {Domains, History, Tasks, Notifications, Collaborators}
    })
    export default class LayoutTooltip extends Vue {
        @Prop(String) readonly context!: string;
        @Prop() readonly loading!: boolean;

        get isNotifications() {
            return this.context === "notifications";
        }

        get isTasks() {
            return this.context === "tasks";
        }

        get isHistory() {
            return this.context === "history";
        }

        get isDomains() {
            return this.context === "domains";
        }

        get isCollaborators() {
            return this.context === "collaborators";
        }

        closeTooltip() {
            this.$emit('close');
        }
    }
