
import {Component, Vue, Prop} from 'vue-property-decorator';
import {IUserDB, LabelValue, StoreAction, StoreMutation} from "@/types";
import {Action, Getter, Mutation, State} from "vuex-class";
import {ROUTES} from "@/data";
import RoundRadios from "@/components/RoundRadios.vue";

@Component({
    name: "Collaborators",
    components: {RoundRadios}
})
export default class Collaborators extends Vue {
    @Mutation('setSelectedCollaborators') setSelectedCollaborators!: StoreMutation;
    @State('currentUser') currentUser!: IUserDB;
    @State('collaborators') collaborators!: IUserDB[];
    @Getter('isManager') isManager!: boolean;
    @Getter('isCompany') isCompany!: boolean;

    get displayed(): IUserDB[] {
        return [
            {
                firstname: "Tous les collaborateurs",
                email: "",
                lastname: "",
                _id: "all"
            },
            {
                firstname: "Moi",
                email: "",
                lastname: "",
                _id: "mine"
            },
            ...this.collaborators
        ];
    }

    onSelect(selected: string[]) {
        this.setSelectedCollaborators(selected);
    }

    collabLabel(user: IUserDB) {
        return user.firstname + " " + user.lastname;
    }

    goToUsers() {
        this.$emit('close');
        if (this.$route.name !== ROUTES.APP.COLLABORATORS) {
            this.$router.push({name: ROUTES.APP.COLLABORATORS});
        }
    }
}
