
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";

    @Component({
        name: "RenameView"
    })
    export default class RenameView extends mixins(ModalMixin) {
        name = "RenameView";
        modelName = ""

        @Prop() view!: any;

        beforeMount() {
            this.modelName = this.view.name;
        }
    }
