
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import {mixins} from "vue-class-component";
    import ModalMixin from "@/components/modal/ModalMixin.vue";

    @Component({
        name: "LockedAction",
        components: {}
    })
    export default class LockedAction extends mixins(ModalMixin) {
        @Prop(String) message!: string
        name = "LockedAction";
    }
